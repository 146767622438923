import { useTranslation } from "@health/i18n";
import { Box } from "@health/ui";
import React, { useEffect } from "react";

type OrderToPrintProps = {
  refForPrint: React.RefObject<HTMLDivElement>;
  // number: string;
  toPrint: Date | undefined;
  awb: string;
  setPrintReady: React.Dispatch<React.SetStateAction<Date | undefined>>;
};

const OrderReceiptPrint = ({ refForPrint, toPrint, setPrintReady, awb }: OrderToPrintProps) => {
  const { i18n } = useTranslation("vendor");

  useEffect(() => {
    if (toPrint) {
      setPrintReady(new Date());
    }
  }, [toPrint]);

  return (
    <Box display={window.matchMedia("print") ? "none" : "flex"}>
      <div dir={i18n.dir(i18n.language)} ref={refForPrint}>
        <Box height='100vh' bgcolor='transparent' display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
          <Box component='iframe' sx={{ border: "none", height: 762, display: "flex", width: "calc( 100% )", mx: 0 }} src={awb} />
        </Box>
      </div>
    </Box>
  );
};

export default OrderReceiptPrint;
