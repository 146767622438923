/* eslint-disable sonarjs/no-duplicate-string */
import { getMediaLink } from "@health/common";
import { i18n, useTranslation } from "@health/i18n";
import { DeliveryTimeSlot, OrderDeliveryStatusEnum, OrderLine } from "@health/queries";
import { Avatar, Box, CustomIcon, Stack, TruncateTypography, Typography } from "@health/ui";
import moment from "moment";
import { FC } from "react";
import { formatDeliveryTimeSlot } from "../utils";
import { OrderSection } from "./OrderSection";
import { useOrdersFromCheckoutStyles } from "./ordersFromCheckout.styles";

type OrdersFromCheckoutProps = {
  city: string;
  number: number;
  color: string;
  products: OrderLine[];
  deliveryStatus: OrderDeliveryStatusEnum;
  deliveryDate: string | undefined;
  branchName: string;
  deliveryTimeSlot: DeliveryTimeSlot | undefined;
};

export const OrdersFromCheckout: FC<OrdersFromCheckoutProps> = props => {
  const { number, deliveryStatus, products, city, color, branchName, deliveryDate, deliveryTimeSlot } = props;
  const { t } = useTranslation("vendor");
  const { classes } = useOrdersFromCheckoutStyles();

  const deliveryDateString = moment(deliveryDate?.toString(), "YYYY-MM-DD");
  const deliveryTimeSubtitle = `
              ${deliveryDateString.locale(i18n.language).format(i18n.language === "ar" ? "dddd" : "ddd")},
              ${deliveryDateString.locale("en").format(i18n.language === "ar" ? "DD/MM" : "MM/DD")}
              ${formatDeliveryTimeSlot(deliveryTimeSlot?.startTime, deliveryTimeSlot?.endTime, i18n.language)}
              `;

  return (
    <>
      <Box sx={{ borderTop: "1px solid #DFE8F2" }} />
      {deliveryDate && (
        <>
          <Stack direction={"row"} justifyContent='space-between' alignItems={"center"}>
            <OrderSection title={t("Delivery Time")} subTitle={deliveryTimeSubtitle} color={color} />
            <CustomIcon width={28} height={28} viewBox='0 0 27 27' icon={"clock"} />
          </Stack>
          <Box sx={{ borderTop: "1px solid #DFE8F2" }} />
        </>
      )}
      <OrderSection title={t("Order ID")} subTitle={String(number)!} color={color} />
      <Box sx={{ borderTop: "1px solid #DFE8F2" }} />
      <OrderSection title={t("Branch")} subTitle={branchName} color={color} />
      {deliveryStatus && (
        <Box sx={{ borderTop: "1px solid #DFE8F2" }}>
          <OrderSection title={t("Delivery Status")} subTitle={deliveryStatus} color={color} />
        </Box>
      )}
      <OrderSection hasBG title={t("Products")} color={color}>
        {products?.map(line => (
          <Box key={line?.id} className={classes.box}>
            <Avatar variant='square' src={getMediaLink(line?.thumbnail?.url!)} className={classes.avatar} />
            <div>
              <TruncateTypography text={line?.productName} className={classes.text} />
              <Typography className={classes.text}>
                {t("Qty")}: {line.quantity}
              </Typography>
            </div>
          </Box>
        ))}
      </OrderSection>
      <OrderSection title={t("Delivery address")} subTitle={city} color={color} />
    </>
  );
};
