import { makeVar, useReactiveVar } from "@apollo/client";
import {
  AccountNotificationEvent,
  areStatuesEqual,
  EVENT_TOPIC,
  OrderStatusChangedNotificationEvent,
  TOPIC_LISTENER,
  useSubscribeToNotificationTopic,
} from "@health/common";
import { useTranslation } from "@health/i18n";
import { MeNotificationsQuery, OrderStatus, useMarkNotificationsAsSeenMutation, useMeNotificationsQuery } from "@health/queries";
import { useCallback, useEffect, useRef, useState } from "react";
import { pharmacyUserPreferencesVar } from "../PharmacyBranch";
import { notify } from "./notifyDesktop";

const notificationSoundFiles = makeVar<Record<string, HTMLAudioElement>>({});

export const useNotificationsHooks = () => {
  const { t } = useTranslation("vendor");
  const { data, loading, fetchMore, refetch } = useMeNotificationsQuery({
    variables: {
      first: 10,
    },
  });
  const [unseenNewOrdersNotificationsCount, setUnseenNewOrdersNotificationsCount] = useState(0);
  const unseenNewOrdersNotificationsCountRef = useRef(unseenNewOrdersNotificationsCount);
  unseenNewOrdersNotificationsCountRef.current = unseenNewOrdersNotificationsCount;
  const [markNotificationsAsSeen] = useMarkNotificationsAsSeenMutation();

  // Get logged-in branch / branches ID
  const pharmacyUserPreferences = useReactiveVar(pharmacyUserPreferencesVar);
  const loggedInBranchIds = pharmacyUserPreferences?.branch.map(branch => branch.id) || [];

  // Filter notifications based on branchId
  const notifications = data?.me?.notifications?.edges
    ?.map(notification => notification?.node)
    .filter(notification => {
      const extraData = notification.extraData ? JSON.parse(notification.extraData.replace(/'/g, '"')) : null;
      return loggedInBranchIds.includes(extraData?.branchId);
    });

  // const notifications = data?.me?.notifications?.edges?.map(notification => notification?.node);
  // const totalCount = data?.me?.notifications?.totalCount;
  const [totalCount, setTotalCount] = useState(notifications?.length); // Update total count based on filtered notifications
  const unReadNotificationsCount = data?.me?.unReadNotificationsCount;

  const pageInfo = data?.me?.notifications?.pageInfo;

  const [open, setOpen] = useState(false);
  // const handleToggle = () => {
  //   unReadNotificationsCount && !open && markNotificationsAsSeen();
  //   open && setUnseenNewOrdersNotificationsCount(0);
  //   setOpen(!open);
  // };
  const handleToggle = () => {
    if (open && unReadNotificationsCount) {
      // If we're closing the dialog, and there's unread notifications, mark notifications as seen
      markNotificationsAsSeen();
      setUnseenNewOrdersNotificationsCount(0);
      setTotalCount(0);
    }
    // Else, we're opening the dialog, do nothing related to marking and Just open the dialog
    setOpen(prevOpen => !prevOpen);
  };
  const onNotificationCreated: TOPIC_LISTENER<AccountNotificationEvent>[EVENT_TOPIC.ACCOUNT_NOTIFICATION] = useCallback(() => {
    refetch();
  }, []);
  useSubscribeToNotificationTopic(EVENT_TOPIC.ACCOUNT_NOTIFICATION, onNotificationCreated);
  useSubscribeToNotificationTopic(EVENT_TOPIC.ORDER_STATUS_UPDATE, onNotificationCreated);

  useEffect(() => {
    open && unReadNotificationsCount && refetch();
  }, [open]);

  const fetchMoreData = () => {
    if (pageInfo?.hasNextPage) {
      fetchMore({
        variables: {
          first: 5,
          after: pageInfo?.endCursor,
        },
        updateQuery: (prev: MeNotificationsQuery, { fetchMoreResult }: any) => {
          if (!fetchMoreResult) return prev;
          return {
            ...fetchMoreResult,
            me: {
              ...fetchMoreResult?.me,
              notifications: {
                ...fetchMoreResult?.me?.notifications,
                edges: [...(prev?.me?.notifications?.edges || []), ...(fetchMoreResult?.me?.notifications?.edges || [])],
              },
            },
          };
        },
      });
    }
  };

  const onOrderStatusChanged: TOPIC_LISTENER<OrderStatusChangedNotificationEvent>[EVENT_TOPIC.ORDER_STATUS_UPDATE] = useCallback(
    ({ data }) => {
      window.dispatchEvent(new CustomEvent<OrderStatusChangedNotificationEvent["data"]>("orderStatusChanged", { detail: data }));
      const orderStatus = data?.status as OrderStatus;

      if (areStatuesEqual(orderStatus, OrderStatus.NewRequest)) {
        unseenNewOrdersNotificationsCountRef.current = unseenNewOrdersNotificationsCountRef.current + 1;
        const message = t(unseenNewOrdersNotificationsCountRef.current == 1 ? "You have new order" : "You have {{ value }} new orders", {
          value: unseenNewOrdersNotificationsCountRef.current,
        });
        playAudio("newOrderNotification.wav");
        notify({ message }, () => {
          // window.open(`${location.protocol}//${location.host}/orders`, "_blank");
        });
        setUnseenNewOrdersNotificationsCount(unseenNewOrdersNotificationsCountRef.current);
      } else if (areStatuesEqual(orderStatus, OrderStatus.Allocated)) {
        playAudio("orderAllocatedNotification.mp3");
      }
    },
    []
  );

  useSubscribeToNotificationTopic(EVENT_TOPIC.ORDER_STATUS_UPDATE, onOrderStatusChanged);

  function playAudio(file: string) {
    if (!notificationSoundFiles()[file]) {
      notificationSoundFiles({ ...notificationSoundFiles(), [file]: new Audio(file) });
    }

    notificationSoundFiles()[file]?.play();
  }

  return {
    open,
    loading,
    pageInfo,
    totalCount,
    notifications,
    unReadNotificationsCount: (unReadNotificationsCount || 0) + unseenNewOrdersNotificationsCount,
    unseenNewOrdersNotificationsCount,
    handleToggle,
    fetchMoreData,
  };
};
