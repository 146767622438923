import { useTranslation } from "@health/i18n";
import { formatMessageErrors, useSetMarketPlaceOrderOutForDeliveryMutation } from "@health/queries";
import { Button, Dialog, DialogActions, DialogTitle, Typography, useAddToast } from "@health/ui";
import { FC, useState } from "react";
import { CircularIntegration } from "./CircularIntegration";
import { useOrderActionStyles } from "./OrderAction.styles";

export const SetMarketPlaceOrderReadyForDeliveryButton: FC<{ id: string; submitLabel: string; deliveryDate: string | undefined }> = ({
  id,
  submitLabel,
  deliveryDate,
}) => {
  const { t } = useTranslation("vendor");
  const { classes } = useOrderActionStyles();
  const { failed, succeeded } = useAddToast();

  const [openDialog, setOpenDialog] = useState(false);

  const [fetchSetMarketPlaceOrderOutForDelivery, { loading }] = useSetMarketPlaceOrderOutForDeliveryMutation({
    onCompleted: data => {
      if (data?.setOrderOutForDelivery?.orderErrors?.length === 0) {
        succeeded(t("Order delivery started successfully"));
      } else {
        const msg = formatMessageErrors(data?.setOrderOutForDelivery?.orderErrors);
        failed(t(msg));
      }
    },
    onError: ({ message }) => {
      failed(t(message));
    },
  });

  const handleOrderVendorFinishProcessing = (): void => {
    if (deliveryDate && !isToday(deliveryDate)) {
      setOpenDialog(true);
    } else {
      handleProceedWithOrder();
    }
  };

  const handleProceedWithOrder = () => {
    handleCloseDialog();
    fetchSetMarketPlaceOrderOutForDelivery({
      variables: { id },
    });
  };

  const className = classes.accept;

  const isToday = (dateString: string) => {
    const today = new Date();
    const deliveryDateString = new Date(dateString);
    return (
      today.getDate() === deliveryDateString.getDate() &&
      today.getMonth() === deliveryDateString.getMonth() &&
      today.getFullYear() === deliveryDateString.getFullYear()
    );
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <>
      <CircularIntegration loading={loading}>
        <Button disabled={loading} className={className} onClick={handleOrderVendorFinishProcessing}>
          {submitLabel}
        </Button>
      </CircularIntegration>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          <Typography variant='h3' sx={{ color: "warning.main" }}>
            {t("Warning")}
          </Typography>
        </DialogTitle>
        <Typography sx={{ px: 2 }}>{`${t("order_delivery_warning1")} ${deliveryDate} ${t("order_delivery_warning2")}`}</Typography>
        <DialogActions>
          <Button color='success' sx={{ color: "success.main" }} onClick={handleCloseDialog}>
            {t("Close")}
          </Button>
          <Button variant='text' sx={{ color: "error.main" }} onClick={handleProceedWithOrder}>
            {t("Proceed Anyway")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
