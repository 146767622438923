import { useTranslation } from "@health/i18n";
import { Box, CircularProgress, Typography } from "@health/ui";
import { FC } from "react";
import { AcceptMarketplaceOrder } from "./AcceptMarketplaceOrder";
import { DispenseOtpVerificationButton } from "./DispenseOtpVerificationButton";
import { GetWasfatyApprovalButton } from "./GetWasfatyApprovalButton";
import { useOrderActionStyles } from "./OrderAction.styles";
import { OrderCancelButton } from "./OrderCancelButton";
import { RejectReasonButton } from "./RejectReasonButton";
import { ResubmitButton } from "./ResubmitButton";
import { SetMarketPlaceOrderReadyForDeliveryButton } from "./SetMarketPlaceOrderReadyForDeliveryButton";
import { IOrderActions } from "./types";
import { ViewAndAcceptButton } from "./ViewAndAcceptButton";
import { ViewQRButton } from "./ViewQRButton";

export const OrderActions: FC<IOrderActions> = ({ data }) => {
  const { t } = useTranslation("vendor");
  const { classes } = useOrderActionStyles();

  const {
    deliveryDate,
    referenceNumber,
    iseRxOrder,
    patientIdentifier,
    medications,
    type,
    id,
    number,
    status,
    patientName,
    patientMobile,
    action,
  } = data;
  const hasAction = Object.values(action!).some(value => value);

  return (
    <>
      {hasAction && (
        <div className={classes.buttons}>
          {action?.hasAcceptMarketPlaceOrderButton && (
            <>
              <AcceptMarketplaceOrder orderId={id!} />
              <RejectReasonButton id={id!} />
            </>
          )}
          {action?.hasAcceptAndViewButton && (
            <>
              <ViewAndAcceptButton
                referenceNumber={referenceNumber}
                patientIdentifier={patientIdentifier!}
                medications={medications!}
                orderId={id!}
              />
              <RejectReasonButton id={id!} />
            </>
          )}
          {action?.hasResubmitButton && (
            <ResubmitButton
              status={status!}
              orderId={id!}
              referenceNumber={referenceNumber}
              patientIdentifier={patientIdentifier!}
              medications={medications!}
            />
          )}
          {action?.hasApproval && <GetWasfatyApprovalButton id={id!} />}
          {action?.hasDispenseDeliveryOrderButton && (
            <Box sx={{ display: "block", width: "100%", flex: 0, textAlign: "center" }}>
              <CircularProgress size={14} sx={{ mx: 2 }} />
              <Typography fontSize={14} sx={{ minWidth: 190 }}>
                {t("Waiting patient confirmation")}
              </Typography>
            </Box>
          )}

          {action?.hasCancelButton && (
            <OrderCancelButton
              isMarketPlaceOrder={!iseRxOrder}
              id={String(id)}
              patientName={String(patientName)}
              patientMobile={patientMobile!}
              status={status}
            />
          )}
          {action?.hasViewQRButton && (
            <>
              <ViewQRButton number={Number(number)} type={type} />
            </>
          )}
          {action?.hasVerifyAndDispenseButton && <DispenseOtpVerificationButton id={id!} />}
          {action?.hasReadyForDeliveryButton && (
            <SetMarketPlaceOrderReadyForDeliveryButton id={id!} submitLabel={t("Ready for Delivery")} deliveryDate={deliveryDate} />
          )}
          {action?.hasViewQRWithAWBButton && <ViewQRButton type={type} hasAwb number={Number(number)} id={id} />}
        </div>
      )}
    </>
  );
};
